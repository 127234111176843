.forth-main {
  width: 90%;
  max-width: 1240px;
  height: fit-content;
  margin: 100px auto;
  position: relative;
  z-index: 100;
  margin-top: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
.forth-left {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 30px;
}
.forth-left h2 {
  width: 70%;
  word-wrap: break-word;
  color: #deff02;
  font-family: "Chakra Petch", sans-serif;
  font-size: 36px;
  font-weight: 500;
  line-height: 52px;
  letter-spacing: 0.08em;
  text-align: left;
  position: relative;
  z-index: 5;
}
.forth-left img {
  /* width: 500px; */
  max-width: 100%;
  margin-top: 30px;
  transition: all 0.5s ease;
}
.forth-left img:hover {
  /* transform: scale(1.1); */
  /* filter: drop-shadow(1px 0 5px #ddff0250); */
  filter: drop-shadow(0px 5px 10px rgba(222, 255, 2, 0.25));
}
.forth-icon {
  height: 42px;
}
.forth-right {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-left: 30px;
}
.forth-right-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px;
  margin-top: 20px;
}
.forth-right-item button {
  cursor: pointer;
  width: max-content;
  background-color: transparent;
  border: 2px solid #deff02;
  font-family: "IBM Plex Sans", sans-serif;
  color: #deff02;
  padding: 10px 20px;
  border-radius: 1000px;
  font-size: 14px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.08em;
  text-align: center;
}
.forth-right-item span {
  width: 80%;
  word-wrap: break-word;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0.08em;
  text-align: left;
  color: #ffffffb4;
}

@media (max-width: 768px) {
  .forth-main {
    margin: 30px auto;
    margin-bottom: 0;
    flex-wrap: wrap;
  }
  .forth-left {
    margin-right: 0;
    width: 100%;
  }
  .forth-right {
    width: 100%;
  }
  .forth-left h2 {
    width: 95%;
    font-size: 1.6rem;
    text-align: center;
  }
  .forth-left img {
    margin-top: 0px;
    margin: 20px auto;
    width: 250px;
  }
  .forth-right-item span {
    width: 90%;
  }
}
