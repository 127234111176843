.fifth-container {
  position: relative;
  min-height: 600px;
  padding-bottom: 50px;
}
.fifth-main {
  width: 50%;
  max-width: 1240px;
  margin: 0px auto;
  position: relative;
  z-index: 100;
  padding-top: 10%;
  margin-left: 30%;
}
.fifth-main h2 {
  width: 90%;
  word-wrap: break-word;
  font-family: "Chakra Petch", sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 52px;
  letter-spacing: 0.08em;
  text-align: left;
  color: white;
}
.fifth-main p {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 38px;
  letter-spacing: 0.025em;
  text-align: left;
}
.fifth-details {
  margin: 50px 0;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
  margin-left: 30%;
}
.fifth-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 30%;
  position: relative;
}
.fifth-label {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0.08em;
  text-align: left;
  color: #ffffffb4;
}
.fifth-count {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 5rem;
  font-weight: 600;
  line-height: 65px;
  letter-spacing: 0.08em;
  margin-bottom: 15px;
  text-align: left;
  color: #deff02;
}

.fifthsection-i8 {
  position: absolute;
  top: 0;
  left: -15%;
  width: 45%;
  max-width: 700px;
  transition: all 1s ease;
}
.fifthsection-i8:hover {
  transform: scale(1.1) !important;
  filter: drop-shadow(0px 4px 50px rgba(222, 255, 2, 0.25));
}
.fifthsection-i7 {
  width: 30%;
  position: absolute;
  top: -30%;
  right: -10%;
  max-width: 700px;
  z-index: 10;
  /* transform: rotate(-45deg); */
  transition: all 1s ease;
}
.fifthsection-i7:hover {
  transform: scale(1.1);
  filter: drop-shadow(0px 4px 50px rgba(222, 255, 2, 0.25));
}
.fifthsection-i7 img {
  width: 100%;
  transform: rotate(-45deg);
}
.mobile-i7 {
  position: absolute;
  top: -1%;
  width: 150px;
  transform: rotate(-45deg);
  right: -10%;
  z-index: 1000;
}

/* slider styling */
.slider-container {
  width: 80%;
  overflow: hidden;
  position: relative;
}

/* Style for the slider */
.slider {
  display: flex;
  transition: transform 0.5s ease-out; /* Adjust transition duration and timing function */
}

/* Style for the card */
.card {
  flex: 0 0 calc(38.333% - 30px);
  object-fit: contain;
  /* aspect-ratio: 2/4; */
  margin: 0 15px;
  box-sizing: border-box;
  padding: 10px;
  transition: all ease;
  min-height: 400px;
  border-radius: 20px;
}

/* Style for the yellow background */
.card:nth-child(3n + 1) {
  background-color: yellow;
  opacity: 1;
}

/* Style for the white background */
.card:nth-child(3n + 2) {
  background-color: white;
  opacity: 1;
}

/* Style for the reduced opacity */
.card:nth-child(3n + 3) {
  background-color: white;
  opacity: 0.2;
}

/* Style for the slider handle */
.slider-handle {
  width: 40px; /* Increased width to accommodate the image */
  height: 40px; /* Set the desired height for the handle */
  background-color: transparent;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Style for the arrow */
.arrow {
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 12px;
  width: 40px;
  height: 40px;
  border-radius: 999999px;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card img {
  width: 100%;
  object-fit: contain;
  max-height: 349px;
  border-radius: 15px;
}

@media (max-width: 768px) {
  .fifth-container {
    min-height: max-content;
    margin-top: -10%;
  }
  .fifth-main {
    /* padding-bottom: 150px; */
    padding-top: 150px;
    width: auto;
    max-width: none;
    overflow-x: hidden;
    margin-left: 0;
  }
  .fifth-main h2 {
    text-align: center;
    font-size: 1.6rem;
    width: 90%;
    margin: 0 auto;
  }
  .fifth-main p {
    width: 80%;
    margin: 0 auto;
    text-align: center;
  }
  .fifthsection-i7 {
    display: none;
  }
  .fifthsection-i8 {
    bottom: -10px;
    top: auto;
  }
  .fifth-details {
    margin: 40px auto;
    margin-left: 00;
    gap: 10px;
    justify-content: center;
    flex-direction: column;
  }
  .fifth-count {
    font-size: 3rem;
    line-height: 130%;
    margin-bottom: 0;
  }
  .fifth-label {
    font-size: 1.5rem;
    width: 100%;
    text-align: center;
  }

  .fifth-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    right: 0;
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
  }
  .slider-container {
    width: 95%;
    margin-left: 5%;
  }
  .slider {
    flex-wrap: nowrap;
    /* Display cards in a single row */
  }

  .card {
    flex: 0 0 calc(75% - 30px); /* Show only two cards in a row */
    margin: 0 10px;
    min-height: 200px;
    margin: 0 5px;
  }

  /* Adjust other styles as needed for mobile screens */
  .card:nth-child(3n + 1) {
    background-color: yellow;
    opacity: 1;
  }
  .card:nth-child(3n + 2) {
    background-color: white;
    opacity: 0.2 !important;
  }
}
