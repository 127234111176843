.ts-main {
  width: 90%;
  max-width: 1440px;
  margin: 100px auto;
  position: relative;
  z-index: 100;
  margin-top: 200px;
}
.ts-title {
  width: 80%;
  margin: 0 auto;
}
.ts-title h2 {
  color: #deff02;
  text-align: center;
  font-family: "Chakra Petch", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 131%; /* 52.4px */
  letter-spacing: 3.2px;
  max-width: 980px;
  margin: 0 auto;
}
.ts-title p {
  color: #fff;
  text-align: center;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 145.5%; /* 26.19px */
  letter-spacing: 1.44px;
  margin: 20px 0;
}

.ts-flex-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 80px auto;
}
.box-item {
  width: 25%;
  height: 150px;
  border-radius: 30px;
  padding: 1px;
  margin: 1.3rem;
  position: relative;
  z-index: 10;
}
.box-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  z-index: 5;
}

.box-item.box-one::before {
  background: linear-gradient(
      97.59deg,
      rgba(166, 166, 166, 0.0406) 11.48%,
      rgba(229, 223, 223, 0.028) 91.65%
    ),
    linear-gradient(
      108.62deg,
      rgba(222, 255, 2, 0.18) 8.18%,
      rgba(0, 0, 0, 0) 52.87%
    );
  border: 0px solid;

  border-image-source: linear-gradient(
    108.62deg,
    rgba(222, 255, 2, 0.18) 8.18%,
    rgba(0, 0, 0, 0) 52.87%
  );
}

.box-item.box-two::before {
  background: linear-gradient(
      97.59deg,
      rgba(166, 166, 166, 0.0406) 11.48%,
      rgba(229, 223, 223, 0.028) 91.65%
    ),
    linear-gradient(
      183.52deg,
      rgba(222, 255, 2, 0.18) 0.36%,
      rgba(0, 0, 0, 0) 69.62%
    );
  border: 0px solid;

  border-image-source: linear-gradient(
    183.52deg,
    rgba(222, 255, 2, 0.18) 0.36%,
    rgba(0, 0, 0, 0) 69.62%
  );
}

.box-item.box-three::before {
  background: linear-gradient(
      97.59deg,
      rgba(166, 166, 166, 0.0406) 11.48%,
      rgba(229, 223, 223, 0.028) 91.65%
    ),
    linear-gradient(
      237.48deg,
      rgba(222, 255, 2, 0.18) 20.47%,
      rgba(0, 0, 0, 0) 67.63%
    );
  border: 0px solid;

  border-image-source: linear-gradient(
    237.48deg,
    rgba(222, 255, 2, 0.18) 20.47%,
    rgba(0, 0, 0, 0) 67.63%
  );
}

.box-item.box-four::before {
  background: linear-gradient(
      97.59deg,
      rgba(166, 166, 166, 0.0406) 11.48%,
      rgba(229, 223, 223, 0.028) 91.65%
    ),
    linear-gradient(
      20deg,
      rgba(222, 255, 2, 0.18) 20.46%,
      rgba(0, 0, 0, 0) 69.35%
    );
  border: 0px solid;

  border-image-source: linear-gradient(
    219.37deg,
    rgba(222, 255, 2, 0.38) 20.46%,
    rgba(0, 0, 0, 0) 69.35%
  );
}

.box-item.box-five::before {
  background: linear-gradient(
      20deg,
      rgba(166, 166, 166, 0.0406) 50%,
      rgba(229, 223, 223, 0.028) 95%
    ),
    linear-gradient(
      330deg,
      rgba(222, 255, 2, 0.18) 20.47%,
      rgba(0, 0, 0, 0) 67.63%
    );
  border: 0px solid;

  border-image-source: linear-gradient(
    237.48deg,
    rgba(222, 255, 2, 0.18) 20.47%,
    rgba(0, 0, 0, 0) 67.63%
  );
}
.box-text {
  /* background-color: #000000; */
  /* background-color: black; */
  background: rgb(9, 9, 9);

  position: relative;
  z-index: 1000;
  width: 100%;
  height: 100%;
  padding: 50px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffffb4;
}
.box-item span {
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0.08em;
  text-align: left;
}

.i1-parent {
  position: absolute;
  z-index: 2;
  top: -8vw;
  left: -12vw;
  width: 30vw;
  transform: rotate(0deg);
}
.i1-parent:hover img {
  transform: scale(1.1);
  /* filter: drop-shadow(0px 0 15px #ddff0262); */
}
.i1-parent img {
  width: 75%;
  transition: all 1s ease;
}

@media (max-width: 768px) {
  .ts-main {
    margin: 100px auto;
    padding-top: 100px;
    width: 80%;
  }
  .ts-title {
    width: auto;
  }
  .ts-title h2 {
    text-align: center;
    font-size: 1.6rem;
    line-height: 47px;
  }
  .i1-parent {
    top: -5%;
    left: -22%;

    width: 200px;
  }
  .ts-flex-boxes {
    width: 100%;
    margin: 60px auto;
  }
  .box-item {
    width: 100%;
    margin: 10px;
  }
}
