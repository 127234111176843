.fs-container {
  position: relative;
  height: 1000px;
  /* border: 1px solid red; */
}

.fs-main {
  min-height: 75vh;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  z-index: 1000;
  /* margin-top: 50px; */
  /* width: 100vw; */
}

.fs-heading {
  position: relative;
  z-index: 100;
  opacity: 1;
}
/* .gsap-hero {
  scale: 1;
  border: 1px solid red;
} */
.fs-heading h1 {
  color: #dffe00;
  font-weight: 400;
  font-family: "Chakra Petch", sans-serif;
  font-size: 3.5rem;
  line-height: 120%;
  text-align: center;
  width: 80%;
  margin: auto;
  padding-top: 200px;
  word-break: keep-all;
}

.sub-heading {
  opacity: 1;
  font-size: large;
  font-weight: 300;
  font-family: "IBM Plex Sans", sans-serif;
  letter-spacing: 1px;
  max-width: max-content;
  text-align: center;
  width: 70%;
  line-height: 180%;
  margin: 30px auto;
  word-break: break-word;
}

.hero-buttons {
  max-width: fit-content;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.yellow-color-text {
  color: #dffe00;
  font-weight: 600;
}
.hero-buttons .first {
  cursor: pointer;
  border-radius: 28px;
  background: linear-gradient(93deg, #deff02 11.3%, #e4ff30 94.59%);
  border: 1px solid #deff02;
  padding: 15px 30px;
  color: #191918;
  text-align: center;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  width: 205px;
  font-weight: 500;
  line-height: 161%; /* 22.54px */
}
.hero-buttons .first:hover {
  font-weight: 800;
}
.hero-buttons button {
  position: relative;
  z-index: 100000 !important;
}
.arrow-down-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.arrow-down-btn svg {
  stroke-width: 0.5px;
  stroke: rgba(222, 255, 2, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow-down-btn svg path {
  scale: 0.5;
  transform: translate(50%, 50%);
}
.arrow-down-btn:hover svg circle {
  stroke-width: 2px;
  stroke: rgba(222, 255, 2, 0.7);
}
.arrow-down-btn:hover svg circle {
  stroke-width: 2px;
  stroke: rgba(222, 255, 2, 0.7);
}
.arrow-down-btn:hover svg path {
  scale: 1;
  transform: none;
}
.bg-hexagon-parent {
  position: absolute;
  top: 0;
  z-index: -1;
  /* bottom: 0; */
  right: 50%;
  transform: translateX(50%);
  width: 100%;
  margin: 0 auto;
  max-width: 1300px; /* This ensures the container won't exceed the viewport width */
  box-sizing: border-box;
  /* width: 100%; */
}
.bg-hexagon {
  position: relative;
  min-height: 1000px;
}
.big-hexagon {
  position: absolute;
  top: -100px;
  left: 3%;
  width: 85%;
  /* opacity: 0; */
  opacity: 1;
}
.small-hexagon {
  position: absolute;
  top: 0px;
  width: 60%;
  left: 16%;
  /* opacity: 0; */
  opacity: 1;
}

.bg-hexagon-parent .circle {
  position: absolute;

  border-radius: 999999px;
  z-index: 20000;
  background-color: black;
}
.without-circle {
  position: absolute;
  z-index: 2;
}
.circle-one {
  /* opacity: 0; */
  opacity: 1;
  z-index: 10000;
  width: 150px;
  height: 150px;
  top: 25%;
  left: 0%;
  border: 1px solid #252525;
  background: black;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle-two {
  /* opacity: 0; */
  opacity: 1;
  width: 160px;
  height: 160px;
  top: 57%;
  right: 23%;
  border: 1px solid #252525;
  background: black;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle-three {
  /* opacity: 0; */
  opacity: 1;
  top: 55%;
  left: 14%;
  width: 25%;
  /* height: 400px; */
}

.bg-yellow {
  width: 28%;
  max-width: 500px;
  position: absolute;
  top: 0;
  right: 0;
}

.i5 {
  width: 70%;
  transition: all 1s ease;
  position: relative;
  z-index: 20000000;
}
.i5:hover {
  transform: scale(1.1) !important;
  filter: drop-shadow(0px 4px 50px rgba(222, 255, 2, 0.35));
}
.i2-parent {
  width: 80%;
  /* transform: rotate(90deg); */
}
.i2-parent:hover img {
  transform: scale(1.1) !important;
  filter: drop-shadow(0px 4px 50px rgba(222, 255, 2, 0.35));
}

.i2-parent img {
  width: 100%;
  transition: all 1s ease;
}

.i3-parent {
  transform: rotate(20deg);
}
.i3-parent:hover img {
  transform: scale(1.1) !important;
  filter: drop-shadow(0px 4px 50px rgba(222, 255, 2, 0.25));
  /* box-shadow: 0px 4px 78px 0px rgba(222, 255, 2, 0.15); */
  /* filter: drop-shadow(1px 0 1px #ddff0250); */
}
.i3-parent img {
  width: 100%;
  transition: all 1s ease;
}

.mobile-only {
  display: none;
}
.mobile-i3 {
  position: absolute;
  top: 0px;
  width: 200px;
  left: -20%;
  z-index: -1;
}
.mobile-i4 {
  position: absolute;
  bottom: 0px;
  width: 200px;
  right: -20%;
  z-index: -1;
}
/* @media (max-width: 1240px) {
  .bg-hexagon {
    min-height: 800px;
  }
} */
@media (max-width: 768px) {
  .fs-main {
    height: 700px;
    overflow-x: hidden;
    /* border: 1px solid red; */
  }
  .fs-container {
    height: auto;
  }
  .fs-heading h1 {
    font-size: 2.2rem;
    width: 80%;
    padding-top: 200px;
  }
  .sub-heading {
    font-size: medium;
    text-align: center;
    width: 75%;
    line-height: 180%;
    margin: 30px auto;
    word-break: break-word;
  }
  .bg-hexagon-parent {
    display: none;
  }
  .mobile-only {
    display: block;
  }

  .bg-yellow {
    width: 40%;
  }
  /* .hero-buttons .first {
    padding: 10px 20px;
    font-size: 12px;
  } */
}
