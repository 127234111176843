.loaderMain {
  height: 100vh;
  width: 100vw;
  background-color: black;
  position: fixed;
  top: 0;
  z-index: 50000;
}
.overlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 2000;
  display: flex;
}
.bar {
  width: 10vw;
  height: 105vh;
  background-color: #dffe00;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  z-index: 500000;
}
.counter {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 3000;
  color: black;
  font-family: "Chakra Petch", sans-serif;
  padding: 0.2em 0.4em;
  font-size: 20vw;
}
