.roadmap-title-main {
  width: 80%;
  margin: 0 auto;
  margin-top: 6rem;
}

.rmMain-parent {
  position: absolute;
  z-index: 1;
  top: -2%;
  right: -33%;
  width: 57%;
  transform: rotate(3598deg);
}

.rmMain-parent img {
  width: 75%;
  transition: all 1s ease;
  /* filter: brightness(65%); */
}

.rmMain-parent:hover img {
  transform: scale(1.1);
  filter: drop-shadow(0px 0 15px #ddff0262);
}

.roadmap-title-main h2 {
  color: #deff02;
  text-align: center;
  font-family: "Chakra Petch", sans-serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: 131%;
  letter-spacing: 0.02em;
  margin: 0 auto;
}

.roadmap-title-main p {
  color: #fff;
  text-align: center;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 145.5%;
  letter-spacing: 1.44px;
  margin: 20px 0;
}

.roadmap-journey {
  display: flex;
  flex-direction: column;
  margin: 3% auto;
  margin-bottom: 4rem;
}

.roadmap-item {
  display: flex;
  align-items: center;
  margin: 30px auto;
  width: 59%;
  margin-right: 24%;
}

.roadmap-date {
  color: #deff02;
  font-family: "Chakra Petch", sans-serif;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1.44px;
  width: 35%;
  letter-spacing: 7.5%;
  display: flex;
  /* justify-content: flex-end; */
  justify-content: center;
  margin-right: -13px;
}

.roadmap-icon-title {
  display: flex;
  cursor: default;
  align-items: center;
  width: 100%;
  margin-left: 4%;
  height: 90px;
}

.roadmap-icon-outermain {
  width: 20%;
}
.roadmap-icon-main {
  background-color: #fff;
  border-radius: 2rem;
  padding: 13px;
  width: 50px;
  height: 50px;
  font-size: 2px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.roadmap-title-text-main {
  width: 82%;
}

.roadmap-title-text {
  border: 1px solid #fff;
  padding: 2rem;
  border-radius: 11px;
  font-family: "Chakra Petch";
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.075em;
  text-align: center;
  margin: 15px 0;
}

.roadmap-icon-title:hover .roadmap-title-text {
  box-shadow: 0px 0px 32.5px 0px #deff026e;
  color: #deff02;
  border-color: #deff02;
}

.roadmap-icon-title:hover .roadmap-icon-main {
  background-color: #deff02;
  box-shadow: 0px 0px 32.5px 0px #deff026e;
}

@media (max-width: 1245px) {
  .rmMain-parent {
    display: none;
  }

  .rmMain-parent img {
    display: none;
  }
}

@media (max-width: 768px) {
  .rmMain-parent {
    display: none;
  }

  .rmMain-parent img {
    display: none;
  }

  .roadmap-title-main {
    width: 90%;
    margin: 45px auto;
  }

  .roadmap-title-main h2 {
    font-size: 28px;
  }

  .roadmap-title-main p {
    font-size: 14px;
  }

  .roadmap-journey {
    width: 90%;
    margin-bottom: 3rem;
  }

  .roadmap-item {
    width: 80%;
    margin: 15px auto;
    display: block;
    padding: 0px 0px 8px 0px;
  }

  .roadmap-date {
    font-size: 16px;
    margin: 10px auto;
    width: auto;
  }

  .roadmap-icon-title {
    height: auto;
    margin: 0;
    gap: 15px;
  }

  .roadmap-title-text-main {
    width: 100%;
  }

  .roadmap-title-text {
    padding: 1rem;
    font-size: smaller;
  }
}
