/* Navbar.css */
.navbar-parent {
  width: 100%;
  margin: auto;

  /* -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  background-color: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid white; */
  position: sticky;
  top: 0;
  z-index: 5000;
  padding-top: 20px;
}
.backdrop-filter {
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  width: 90%;
  /* max-width: 1240px; */
  height: 58px;
  margin: auto;
}
.logo {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 0px;
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  font-family: "Chakra Petch", sans-serif;
}
.logo > img {
  width: 200px;
  /* height: 2.5rem; */
  /* text-decoration: none; */
}

.nav-links {
  display: flex;
  gap: 0px;
  background: linear-gradient(90.41deg, #121306 5.33%, #0a0a02 98.67%),
    linear-gradient(0deg, rgba(222, 255, 2, 0.75), rgba(222, 255, 2, 0.75));
  max-width: 800px;
  display: flex;
  justify-content: center;
  /* padding: 10px 0; */
  border: 0.4px solid rgba(222, 255, 2, 0.35);
  border-radius: 9999px;
}

.nav-link {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s;
  border: 1px solid transparent;
  border-right-color: rgba(222, 255, 2, 0.35);
  font-size: 14px;
  padding: 1rem 3rem;
  position: relative;
  font-weight: 300;
}

.nav-link:last-of-type {
  border: none;
}
.nav-link:hover {
  color: #dffe00;
  font-weight: 500;
}
.submenu {
  position: relative;
}
.subMenuItemParent {
  list-style-type: none;
  position: absolute;
  top: 52px;
  left: 0%;
  width: 100%;
  /* transform: translateX(50%); */
  background-color: #111206;
  border-radius: 10px;
  transition: visibility 0.3s ease;
}
.submenu .subMenuItemParent {
  visibility: hidden;
  opacity: 0;
}
.submenu:hover > .subMenuItemParent {
  visibility: visible;
  opacity: 1;
}

.subMenuItem {
  padding: 8px 10px;
  word-wrap: break-word;
  text-align: center;
  color: #fff;
  text-align: center;
  font-family: "Chakra Petch", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 145.5%; /* 14.55px */
}
.subMenuItem a {
  color: white;
  text-decoration: none;
}
.subMenuItem:hover a {
  color: #dffe00;
}

.cta-button {
  order: 1;
  color: #000;
  padding: 8px;
  padding-right: 0;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100px;
}

.mobile-close-icon {
  font-size: 24px;
  cursor: pointer;
  display: none;
}
.close {
  display: none;
}
.mobile-menu {
  display: none;
}
.mobile-menu.show {
  z-index: 1;
  display: flex;
  opacity: 1;
  transform: translateY(0);
  background: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  height: calc(100vh - 58px);
}

.mobile-menu a {
  padding: 15px;
  text-align: center;
  text-decoration: none;
  color: white;
}

.mobile_header_submenu {
  pointer-events: none;
  list-style-type: none;
  display: none;
  font-size: 14px;
  margin: 10px 20px;
  transition: all 0.5s ease-in-out;
}
.mobile_nav_link {
  background-color: #000;
  padding: 15px 20px;
}
.mobile_nav_link.show .mobile_header_submenu {
  display: block;
}
.mobile_nav_link .nav-text-animate > svg {
  transition: all 0.5s ease;
}
.mobile_nav_link.show .nav-text-animate > svg {
  transform: rotate(180deg);
}

@media (max-width: 1100px) {
  .logo {
    font-size: 14px;
  }
  .logo > img {
    width: 150px;
    /* height: 2rem; */
  }
  .nav-link {
    padding: 5px 30px;
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .navbar-parent {
    height: 10vh;
  }
  .navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
    /* background-color: black; */
    color: white;
    position: relative;
    z-index: 50;
  }
  .logo {
    order: 1;
    font-size: 1rem;
  }
  .logo > img {
    /* height: 2rem; */
    width: 200px;
  }
  .mobile-menu-icon {
    order: 2;
    background-color: #000;
    padding: 8px;
    border-radius: 10px;
  }

  .nav-links {
    display: none;
  }
  .nav-links.show {
    transform: translateX(0);
    display: flex; /* Add this line */
    flex-direction: column;
  }
  .mobile_nav_link {
    padding: 15px 20px;
    text-align: center;
    position: relative;
    z-index: -1;
  }
  .mobile-menu.show .mobile_nav_link {
    z-index: 2;
  }
  .mobile-close-icon.show {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 15px;
    right: 20px;
    z-index: 1;
  }
  .mobile-menu-icon.close {
    font-size: 24px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mobile-menu {
    display: flex;
    flex-direction: column;
    background-color: rgba(10, 10, 10, 1);
    position: absolute;
    top: 80px;
    width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: -1;
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0.5s,
      transform 1s cubic-bezier(0.23, 1, 0.32, 1) 0.5s,
      background-color 1s cubic-bezier(0.23, 1, 0.32, 1) 0.5s;
  }
  .mobile-menu.show {
    opacity: 1;
    z-index: 2;
  }
  .mobile-menu a {
    z-index: -1;
    padding: 20px 15px;
    text-align: center;
    text-decoration: none;
    color: white;
    background-color: #000;
    margin: 0;
    font-size: larger;
    pointer-events: none;
    margin-left: 0;
    display: block;
    float: none;
    /* width: 100%; */
    /* height: 60px; */
  }

  .nav-text-animate {
    font-size: large;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    line-height: 1.3;
    opacity: 0;
    transform: translate3d(0, -25px, 0);
    transition: 0.5s ease;
    transition-property: transform, opacity;
  }
  .mobile-menu.show .nav-text-animate {
    transition-delay: 0.7s;
    transform: translateY(0);
    opacity: 1;
  }
  .mobile-menu.show a {
    z-index: 9999;
    pointer-events: all;
  }
  .mobile_menu_link_parent {
    width: 100%;
    height: 0;
    transition: height 0.1s cubic-bezier(0.23, 1, 0.32, 1);
    border-bottom: 2px solid rgba(255, 255, 255, 0.18);
    border-top: 2px solid rgba(255, 255, 255, 0.18);
  }
  .mobile-menu.show .mobile_menu_link_parent {
    height: max-content;
  }
  .cta-button {
    margin-left: auto;
    margin-right: 10px;
  }

  .mobile_header_submenu_item a {
    text-decoration: none;
    font-size: 14px;
    margin: 5px 0;
    padding: 5px 0;
    text-align: left;
  }
}
